import React from "react"
import NavigationBar from "../components/Navbar"
import ThankYouImage from "../img/thank-you.png"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"

const SocialMedia = loadable(() => import("../components/social-media"))
const Footer3 = loadable(() => import("../components/footer3"))

export default function ThankYou() {
  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title>Thank You!</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="Thank you page" />
      </Helmet>
      <div className="mt-1">
        <NavigationBar />
      </div>

      <div className="px-3 xl:px-5 2xl:px-8">
        <div className="grid justify-items-center">
          <h2 className="my-10 text-xl sm:text-2xl xl:text-3xl text-center">
            We are glad to hear from you.
          </h2>
          <div className="-mt-6">
            <img
              className="pointer-events-none object-cover"
              src={ThankYouImage}
              alt="Thank You!"
            />
          </div>
          <SocialMedia />
        </div>

        <Footer3 />
      </div>
    </div>
  )
}
